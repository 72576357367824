<script setup lang="ts">
import { computed } from 'vue'

import { Box, ErrorState, LayoutLanding, Paper, Skeleton, Typography } from '@lasso/luikit'
import { useApi } from '@lasso/shared/hooks'
import { objGroupBy } from '@lasso/shared/utils'

import { Role, RoleAction } from '@admin/api'
import { useAdminApi } from '@admin/hooks/useAdminApi'

type Actions = Record<string, RoleAction[]>

const api = useAdminApi()
const { data, loading, error, retry } = useApi(api.admin.getRoles)

const roles = computed<Role[]>(() => {
  return data.value ?? []
})

const getActions = (role: Role): Actions => {
  const actions = objGroupBy(role.actions, (action) => action.resourceName)
  return actions
}

const getClasses = (index: number) => {
  return index % 2 === 1 ? '' : 'bg-base-300'
}
</script>

<template>
  <LayoutLanding>
    <template #title>Roles</template>
    <Skeleton v-if="loading" height="1000px" />
    <ErrorState v-else-if="error" :error="error" @retry="retry" />
    <Paper v-else flex col>
      <Box class="roles-grid">
        <Box p="6">
          <Typography variant="h5">Role</Typography>
        </Box>
        <Box p="6">
          <Typography variant="h5">Actions</Typography>
        </Box>
        <template v-for="(role, roleIndex) in roles" :key="role.id">
          <Box :class="getClasses(roleIndex)" px="6" py="4">
            <Typography variant="subtitle2">
              {{ role.name }}
            </Typography>
          </Box>
          <Box :class="getClasses(roleIndex)" px="6" py="4">
            <Box v-for="(actions, key) in getActions(role)" :key="key">
              <Typography variant="body2" bold>
                {{ `${key}: ` }}
              </Typography>
              <Typography v-for="(action, index) in actions" :key="action.id" variant="body2">
                {{ `${action.actionName}${index === actions.length - 1 ? '.' : ', '}` }}
              </Typography>
            </Box>
          </Box>
        </template>
      </Box>
    </Paper>
  </LayoutLanding>
</template>

<style scoped>
.roles-grid {
  display: grid;
  grid-template-columns: minmax(300px, auto) minmax(300px, 1fr);
}
</style>
